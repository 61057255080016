var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lg:absolute bg-white lg:bg-purple-5 w-full left-0 my-auto lg-block",class:{
    block: _vm.subNavOpen,
    hidden: !_vm.subNavOpen,
  },attrs:{"data-cy":("navV2-nav-subnav-" + _vm.title),"aria-hidden":_vm.subNavOpen ? 'false' : 'true'}},[_c('div',{staticClass:"container py-2 lg:py-4"},[_c('span',{staticClass:"sr-only lg:not-sr-only text-lg"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_c('span',{staticClass:"hidden lg:block h-1 w-8 mt-3 mb-5 bg-primary rounded-md"}),_c('div',{staticClass:"lg:flex w-full"},_vm._l((_vm.subNav),function(sub,sectionIdx){return _c('div',{key:sub.id,ref:"section-nav",refInFor:true,staticClass:"flex flex-wrap lg:flex-col lg:w-2/12 py-3 px-4 md:px-2 lg:p-0",class:{
          'lg:ml-8 border-t-1 border-purple-5': sectionIdx > 0,
        }},[_c('span',{staticClass:"w-10/12 lg:w-full lg:font-semibold lg:mb-4"},[_vm._v("\n          "+_vm._s(sub.section)+"\n        ")]),_c('button',{staticClass:"lg:hidden relative w-5 h-5 flex items-center justify-center ml-auto",attrs:{"type":"button","aria-label":((sub.open ? 'Close' : 'Open') + " " + (sub.section) + " sub navigation")},on:{"click":function($event){_vm.toggleOpen(sectionIdx);
            _vm.$generalClick(
              {
                category: 'Menu Interaction',
                label: ("Menu Click section - " + (sub.section)),
              },
              $event
            );}}},[_c('Icon',{attrs:{"name":sub.open ? 'Remove' : 'Add'}})],1),_c('ul',{staticClass:"lg:block mt-3 mb-3 lg:mt-0 border-l-1 pl-4 border-purple-10",class:{
            block: sub.open,
            hidden: !sub.open,
          }},_vm._l((sub.links),function(link,linkIdx){return _c('li',{key:link.id,staticClass:"text-sm",class:{ 'mt-3': linkIdx > 0 }},[_c('a',{ref:"subnav-link",refInFor:true,staticClass:"tsm-text-link-plain",attrs:{"href":link.link,"target":"_self"},on:{"click":function($event){_vm.$generalClick(
                  {
                    category: 'Menu Interaction',
                    label: ("Menu Click - " + _vm.channel + " - " + (link.linkText))
                  },
                  $event,
                  link.link
                );
                _vm.$ga4GeneralClick({
                  event: 'top_nav',
                  module: ((_vm.channel.toLowerCase()) + " - " + (sub.section.toLowerCase())),
                  action: 'click',
                  content_display: link.linkText
                })},"keydown":function($event){return _vm.keyboardNavigation(sectionIdx, linkIdx, $event)}}},[_vm._v("\n              "+_vm._s(link.linkText)+"\n            ")])])}),0)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    :data-cy="`navV2-nav-subnav-${title}`"
    class="lg:absolute bg-white lg:bg-purple-5 w-full left-0 my-auto lg-block"
    :class="{
      block: subNavOpen,
      hidden: !subNavOpen,
    }"
    :aria-hidden="subNavOpen ? 'false' : 'true'"
  >
    <div class="container py-2 lg:py-4">
      <span class="sr-only lg:not-sr-only text-lg">
        {{ title }}
      </span>

      <span
        class="hidden lg:block h-1 w-8 mt-3 mb-5 bg-primary rounded-md"
      ></span>

      <div class="lg:flex w-full">
        <div
          v-for="(sub, sectionIdx) in subNav"
          ref="section-nav"
          :key="sub.id"
          class="flex flex-wrap lg:flex-col lg:w-2/12 py-3 px-4 md:px-2 lg:p-0"
          :class="{
            'lg:ml-8 border-t-1 border-purple-5': sectionIdx > 0,
          }"
        >
          <span class="w-10/12 lg:w-full lg:font-semibold lg:mb-4">
            {{ sub.section }}
          </span>

          <button
            type="button"
            :aria-label="`${sub.open ? 'Close' : 'Open'} ${
              sub.section
            } sub navigation`"
            @click="
              toggleOpen(sectionIdx);
              $generalClick(
                {
                  category: 'Menu Interaction',
                  label: `Menu Click section - ${sub.section}`,
                },
                $event
              );
            "
            class="lg:hidden relative w-5 h-5 flex items-center justify-center ml-auto"
          >
            <Icon :name="sub.open ? 'Remove' : 'Add'" />
          </button>

          <ul
            class="lg:block mt-3 mb-3 lg:mt-0 border-l-1 pl-4 border-purple-10"
            :class="{
              block: sub.open,
              hidden: !sub.open,
            }"
          >
            <li
              v-for="(link, linkIdx) in sub.links"
              :key="link.id"
              class="text-sm"
              :class="{ 'mt-3': linkIdx > 0 }"
            >
              <a
                :href="link.link"
                ref="subnav-link"
                target="_self"
                class="tsm-text-link-plain"
                @click="
                  $generalClick(
                    {
                      category: 'Menu Interaction',
                      label: `Menu Click - ${channel} - ${link.linkText}`
                    },
                    $event,
                    link.link
                  );
                  $ga4GeneralClick({
                    event: 'top_nav',
                    module: `${channel.toLowerCase()} - ${sub.section.toLowerCase()}`,
                    action: 'click',
                    content_display: link.linkText
                  })
                "
                @keydown="keyboardNavigation(sectionIdx, linkIdx, $event)"
              >
                {{ link.linkText }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Icon: () =>
      import(/* webpackChunkName: "tsm-icon" */ "../Icon.vue"),
  },
  data() {
    return {
      navigationElementGroups: [],
    };
  },
  props: {
    title: String,
    channel: String,
    subNav: Array,
    subNavOpen: {
      type: Boolean,
      default: false,
    },
    openedWithSpaceKey: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["layout"]),
  },
  methods: {
    blur() {
      let that = this;
      setTimeout(function () {
        if (!document.getElementById("nav").contains(document.activeElement)) {
          that.$parent.$emit("navOpenChange", null);
          that.$root.$emit("showNavOverlay", false);
        }
      }, 100);
    },
    toggleOpen(idx) {
      Vue.set(this.subNav[idx], "open", !this.subNav[idx].open);
      this.$ga4GeneralClick({
        event: 'top_nav',
        module: `${this.channel} - ${this.subNav[idx].section.toLowerCase()}`,
        action: this.subNav[idx].open ? 'expand' : 'collapse'
      })
    },
    closeSection() {
      if (this.layout.includes("desktop")) {
        this.$emit("closedWithSpaceKey", null);
      }
    },
    groupNavigationElements() {
      this.$refs["section-nav"].forEach((el) => {
        this.navigationElementGroups.push(el.querySelectorAll("a"));
      });
    },
    focusLink($event, sectionIdx, linkIdx) {
      $event.preventDefault();
      //make sure can't go left if first column, or right if last column
      if (
        sectionIdx !== -1 &&
        sectionIdx !== this.navigationElementGroups.length
      ) {
        //if first item and not in first column, go to previous column
        if (linkIdx === -1 && sectionIdx > 0) {
          sectionIdx = sectionIdx - 1;
          linkIdx = this.navigationElementGroups[sectionIdx].length - 1;
        }
        //if last item and not in last column, go to next column
        else if (
          linkIdx === this.navigationElementGroups[sectionIdx].length &&
          sectionIdx < this.navigationElementGroups.length
        ) {
          sectionIdx = sectionIdx + 1;
          linkIdx = 0;
        }

        //make sure element array has correct length and that exists otherwise an error is thrown
        if (
          this.navigationElementGroups[sectionIdx] &&
          this.navigationElementGroups[sectionIdx][linkIdx]
        ) {
          this.navigationElementGroups[sectionIdx][linkIdx].focus();
        }
      }
    },
    keyboardNavigation(sectionIdx, linkIdx, $event) {
      if (this.layout.includes("desktop")) {
        if (this.navigationElementGroups.length === 0) {
          this.groupNavigationElements();
        }

        switch ($event.key) {
          case "ArrowLeft":
            this.focusLink($event, sectionIdx - 1, 0);
            break;
          case "ArrowRight":
            this.focusLink($event, sectionIdx + 1, 0);
            break;
          case "ArrowDown":
            this.focusLink($event, sectionIdx, linkIdx + 1);
            break;
          case "ArrowUp":
            this.focusLink($event, sectionIdx, linkIdx - 1);
            break;
          case "Space":
          case "Escape":
            $event.preventDefault();
            this.$emit("closedWithSpaceKey", null);
            break;
        }
      }
    },
  },
  watch: {
    openedWithSpaceKey: function (updated) {
      if (updated) {
        this.$nextTick(() => {
          this.$refs["subnav-link"][0].focus();
        });
      }
    },
  },
};
</script>

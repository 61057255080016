<template>
  <header
    class="sticky top-0 z-10 w-full bg-white border-b-1 border-grey-light"
  >
    <transition name="fade">
      <Overlay v-if="showNavOverlay" @click.native="toggleNav"></Overlay>
    </transition>

    <div class="bg-white relative z-1">
      <div
        class="container flex lg:flex-none flex-wrap items-center justify-between py-3 lg:py-0"
        @keydown="keydown($event)"
      >
        <a
          class="lg:py-2 ml-2 md:ml-0"
          data-cy="navV2-site-logo"
          id="site-logo"
          href="/"
          aria-label="TravelSupermarket logo"
          @click="
            $generalClick(
              { category: 'Menu Interaction', label: 'TSM Logo' },
              $event,
              '/'
            );
            $ga4GeneralClick({
              event: 'top_nav',
              module: 'top nav',
              action: 'logo click'
            })
          "
        >
          <Icon
            name="TsmPrimaryLogo"
            width="250"
            height="48"
            viewBox="24 0 136 24"
            class="text-orange text-orange-default"
          />
        </a>

        <!-- make this a component? -->
        <button
          ref="nav-burger-button"
          data-cy="navV2-burger-menu"
          type="button"
          class="relative lg:hidden w-5 h-6 ml-auto mr-1"
          :aria-label="`${navOpen ? 'Close' : 'Open'} site navigation`"
          aria-controls="nav"
          :aria-expanded="`${navOpen ? 'true' : 'false'}`"
          @click="
            toggleNav();
            $generalClick({
              category: 'Menu Interaction',
              label: 'Menu in Header',
            });
          "
        >
          <Icon 
            :name="navOpen ? 'Close' : 'Menu'" 
            :width="navOpen ? '28' : '32'" 
            :height="navOpen ? '28' : '32'"
          />
        </button>

        <nav
          id="nav"
          data-cy="navV2-nav"
          aria-label="Main Navigation"
          class="absolute left-0 md:right-0 md:left-auto transition-transform duration-200 md:w-1/2 top-full lg:static lg:flex lg:flex-grow lg:flex-grow-default w-full lg:w-auto lg:ml-8 lg:bg-white lg:max-w-9/12 overflow-y-auto lg:overflow-y-visible scrolling-touch bg-purple-5"
          :class="{ hidden: !navOpen, open: navOpen }"
          ref="nav-container"
        >
          <ul
            class="lg:flex lg:w-full lg:justify-around"
            @mouseenter="mouseEnterEvt"
            @mouseleave="mouseLeaveEvt"
          >
            <ChannelNavItem
              v-for="item in navigation"
              :key="item.id"
              :navItem="item"
              @navOpenChange="updateSelectedNav"
              @navClose="navClose"
              :selectedItem="selectedItem"
            ></ChannelNavItem>
          </ul>
          <div class="flex items-center p-4 pb-10 lg:p-0 lg:bg-white">
            <button 
              @click="
                $refs.modal.openModal('nav click', 'top nav email modal');
                $ga4GeneralClick({
                  event: 'top_nav',
                  module: 'sign up',
                  action: 'click'
                })
              "
              class="flex whitespace-no-wrap whitespace-nowrap border-2 m-auto border-text-link rounded rounded-default px-10 lg:px-2 py-2 lg:py-1 font-semibold text-text-link hover:text-text-link-dark">
              <Icon name="Envelope" class="mr-3 lg:mr-1" />
              Sign up
            </button>
          </div>
        </nav>
      </div>
    </div>
  <CrmModal ref="modal"></CrmModal>
  </header>
</template>

<script>
import { mapGetters } from "vuex";
import ChannelNavItem from "./NavigationHeaderV2/ChannelNavItem.vue";
import Icon from "./Icon.vue";
import Overlay from "./Overlay.vue";
import CrmModal from "./CrmModal.vue";

export default {
  name: "NavigationHeader",
  components: {
    ChannelNavItem,
    Icon,
    Overlay,
    CrmModal,
  },
  data() {
    return {
      showNavOverlay: false,
      navOpen: false,
      selectedItem: null,
      navigation: require("../resources/navigation")
    };
  },
  computed: {
    ...mapGetters(["layout"]),
  },
  methods: {
    keydown(evt) {
      if (evt.keyCode === 9) {
        let that = this;
        setTimeout(function () {
          if (!that.$refs["nav-container"].contains(document.activeElement)) {
            if (that.layout.includes("desktop")) {
              that.toggleNav();
              that.updateSelectedNav(null);
              that.$root.$emit("showNavOverlay", false);
            } else if (that.navOpen) {
              that.$refs["nav-burger-button"].focus();
            }
          }
        }, 10);
      }
    },
    updateSelectedNav(payload) {
      this.selectedItem = payload;
    },
    navClose() {
      this.selectedItem = null;
    },
    mouseEnterEvt() {
      if (this.layout.includes("desktop")) {
        this.showNavOverlay = true;
      }
    },
    mouseLeaveEvt() {
      if (this.layout.includes("desktop")) {
        this.showNavOverlay = false;
      }
    },
    toggleNav() {
      this.navOpen = !this.navOpen;
      this.showNavOverlay = this.navOpen;
      if (this.navOpen) {
        document.body.classList.add("overflow-hidden");
      } else {
        document.body.classList.remove("overflow-hidden");
      }
      this.$ga4GeneralClick({
        event: 'top_nav',
        module: 'top nav',
        action: this.navOpen ? 'open menu' : 'close menu'
      })
    },
  },
  mounted() {
    this.$root.$on("showNavOverlay", (val) => {
      this.showNavOverlay = val;
    });
  }
};
</script>

<style lang="scss">
#nav {
  /* can we do this with tailwind?? */
  height: calc(100vh - 60px);
  @screen lg {
    height: auto;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transition: none;
}
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"flex flex-wrap lg:block bg-purple-5 lg:bg-white lg:justify-center border-b-1 border-purple-10 lg:border-b-0",on:{"mouseenter":_vm.mouseEnterEvt,"mouseleave":_vm.mouseLeaveEvt}},[(_vm.navItem.isLink)?_c('a',{ref:"nav-channel-trigger",staticClass:"w-auto inline-flex py-4 px-4 lg:px-0 items-center font-semibold text-secondary lg:border-b-4 border-white hover:border-primary focus:border-primary",class:{'border-primary' : _vm.subNavOpen},attrs:{"data-cy":("navV2-nav-link-" + (_vm.navItem.channel)),"href":_vm.navItem.channelLink,"target":"_self","aria-controls":((_vm.navItem.channel) + " menu"),"aria-expanded":_vm.subNavOpen ? 'true' : 'false'},on:{"focus":function($event){return _vm.focus()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }$event.preventDefault();return _vm.toggleSubNav('space')},"click":function($event){_vm.$generalClick({'category': 'Menu Interaction', 'label': ("Top Nav Click - " + (_vm.navItem.channel))}, $event, _vm.navItem.channelLink);
      _vm.$ga4GeneralClick({
        event: 'top_nav',
        module: _vm.navItem.channel.toLowerCase(),
        action: 'click',
        content_display: _vm.navItem.channel.toLowerCase()
      })}}},[_vm._v("\n    "+_vm._s(_vm.navItem.channel)+"\n    "),_c('Icon',{staticClass:"hidden lg:block ml-1 pointer-events-none transition-transform transform duration-200 text-orange text-orange-default",class:{ 'rotate-180': _vm.hasHover || _vm.subNavOpen },attrs:{"name":"ChevronDown"}})],1):_vm._e(),(!_vm.navItem.isLink)?_c('button',{ref:"nav-channel-trigger",staticClass:"w-auto inline-flex py-4 px-4 lg:px-0 items-center font-semibold text-secondary lg:border-b-4 border-white hover:border-primary focus:border-primary",class:{'border-primary' : _vm.subNavOpen},attrs:{"data-cy":("navV2-nav-link-" + (_vm.navItem.channel)),"disabled":_vm.buttonDisabled,"aria-controls":((_vm.navItem.channel) + " menu"),"aria-expanded":_vm.subNavOpen ? 'true' : 'false'},on:{"focus":function($event){return _vm.focus()},"click":function($event){_vm.toggleSubNav('space');
      _vm.$generalClick({'category': 'Menu Interaction', 'label': ("Top Nav Click - " + (_vm.navItem.channel))}, $event);
      _vm.$ga4GeneralClick({
        event: 'top_nav',
        module: _vm.navItem.channel.toLowerCase(),
        action: 'click',
        content_display: _vm.navItem.channel.toLowerCase()
      })}}},[_vm._v("\n    "+_vm._s(_vm.navItem.channel)+"\n    "),_c('Icon',{staticClass:"hidden lg:block ml-1 pointer-events-none transition-transform transform duration-200 text-orange text-orange-default",class:{ 'rotate-180': _vm.hasHover || _vm.subNavOpen },attrs:{"name":"ChevronDown"}})],1):_vm._e(),_c('button',{staticClass:"group ml-auto w-auto mr-2 md:mr-6 lg:hidden",attrs:{"data-cy":("navV2-nav-subnav-btn-" + (_vm.navItem.channel)),"type":"button","aria-label":((_vm.subNavOpen ? 'Close': 'Open') + " " + (_vm.navItem.channel) + " sub navigation")},on:{"click":function($event){_vm.toggleSubNav();
      _vm.$generalClick({'category': 'Menu Interaction', 'label': ("Sub Menu Nav Click - " + (_vm.navItem.channel) + " sub navigation")}, $event);
      _vm.$ga4GeneralClick({
        event: 'top_nav',
        module: _vm.navItem.channel.toLowerCase(),
        action: _vm.subNavOpen ? 'expand arrow' : 'collapse arrow'
      })}}},[_c('Icon',{staticClass:"transition duration-200 transform text-orange text-orange-default",class:{ 'rotate-180': _vm.subNavOpen },attrs:{"name":"ChevronDown","width":"32","height":"32"}})],1),_c('ChannelSubNav',{ref:"subNav",attrs:{"subNav":_vm.navItem.subNav,"title":_vm.navItem.title || _vm.navItem.channel,"channel":_vm.navItem.channel,"subNavOpen":_vm.subNavOpen,"opened-with-space-key":_vm.openedWithSpaceKey},on:{"closedWithSpaceKey":function($event){return _vm.closeSubNav(true)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
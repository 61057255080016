var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"sticky top-0 z-10 w-full bg-white border-b-1 border-grey-light"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.showNavOverlay)?_c('Overlay',{nativeOn:{"click":function($event){return _vm.toggleNav($event)}}}):_vm._e()],1),_c('div',{staticClass:"bg-white relative z-1"},[_c('div',{staticClass:"container flex lg:flex-none flex-wrap items-center justify-between py-3 lg:py-0",on:{"keydown":function($event){return _vm.keydown($event)}}},[_c('a',{staticClass:"lg:py-2 ml-2 md:ml-0",attrs:{"data-cy":"navV2-site-logo","id":"site-logo","href":"/","aria-label":"TravelSupermarket logo"},on:{"click":function($event){_vm.$generalClick(
            { category: 'Menu Interaction', label: 'TSM Logo' },
            $event,
            '/'
          );
          _vm.$ga4GeneralClick({
            event: 'top_nav',
            module: 'top nav',
            action: 'logo click'
          })}}},[_c('Icon',{staticClass:"text-orange text-orange-default",attrs:{"name":"TsmPrimaryLogo","width":"250","height":"48","viewBox":"24 0 136 24"}})],1),_c('button',{ref:"nav-burger-button",staticClass:"relative lg:hidden w-5 h-6 ml-auto mr-1",attrs:{"data-cy":"navV2-burger-menu","type":"button","aria-label":((_vm.navOpen ? 'Close' : 'Open') + " site navigation"),"aria-controls":"nav","aria-expanded":("" + (_vm.navOpen ? 'true' : 'false'))},on:{"click":function($event){_vm.toggleNav();
          _vm.$generalClick({
            category: 'Menu Interaction',
            label: 'Menu in Header',
          });}}},[_c('Icon',{attrs:{"name":_vm.navOpen ? 'Close' : 'Menu',"width":_vm.navOpen ? '28' : '32',"height":_vm.navOpen ? '28' : '32'}})],1),_c('nav',{ref:"nav-container",staticClass:"absolute left-0 md:right-0 md:left-auto transition-transform duration-200 md:w-1/2 top-full lg:static lg:flex lg:flex-grow lg:flex-grow-default w-full lg:w-auto lg:ml-8 lg:bg-white lg:max-w-9/12 overflow-y-auto lg:overflow-y-visible scrolling-touch bg-purple-5",class:{ hidden: !_vm.navOpen, open: _vm.navOpen },attrs:{"id":"nav","data-cy":"navV2-nav","aria-label":"Main Navigation"}},[_c('ul',{staticClass:"lg:flex lg:w-full lg:justify-around",on:{"mouseenter":_vm.mouseEnterEvt,"mouseleave":_vm.mouseLeaveEvt}},_vm._l((_vm.navigation),function(item){return _c('ChannelNavItem',{key:item.id,attrs:{"navItem":item,"selectedItem":_vm.selectedItem},on:{"navOpenChange":_vm.updateSelectedNav,"navClose":_vm.navClose}})}),1),_c('div',{staticClass:"flex items-center p-4 pb-10 lg:p-0 lg:bg-white"},[_c('button',{staticClass:"flex whitespace-no-wrap whitespace-nowrap border-2 m-auto border-text-link rounded rounded-default px-10 lg:px-2 py-2 lg:py-1 font-semibold text-text-link hover:text-text-link-dark",on:{"click":function($event){_vm.$refs.modal.openModal('nav click', 'top nav email modal');
              _vm.$ga4GeneralClick({
                event: 'top_nav',
                module: 'sign up',
                action: 'click'
              })}}},[_c('Icon',{staticClass:"mr-3 lg:mr-1",attrs:{"name":"Envelope"}}),_vm._v("\n            Sign up\n          ")],1)])])])]),_c('CrmModal',{ref:"modal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
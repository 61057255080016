<template>
  <li
    class="flex flex-wrap lg:block bg-purple-5 lg:bg-white lg:justify-center border-b-1 border-purple-10 lg:border-b-0"
    @mouseenter="mouseEnterEvt"
    @mouseleave="mouseLeaveEvt"
  >
    <a
      :data-cy="`navV2-nav-link-${navItem.channel}`"
      ref="nav-channel-trigger"
      v-if="navItem.isLink"
      :href="navItem.channelLink"
      target="_self"
      @focus="focus()"
      @keydown.space.prevent="toggleSubNav('space')"
      @click="
        $generalClick({'category': 'Menu Interaction', 'label': `Top Nav Click - ${navItem.channel}`}, $event, navItem.channelLink);
        $ga4GeneralClick({
          event: 'top_nav',
          module: navItem.channel.toLowerCase(),
          action: 'click',
          content_display: navItem.channel.toLowerCase()
        })
      "
      :aria-controls="`${navItem.channel} menu`"
      :aria-expanded="subNavOpen ? 'true' : 'false'"
      class="w-auto inline-flex py-4 px-4 lg:px-0 items-center font-semibold text-secondary lg:border-b-4 border-white hover:border-primary focus:border-primary"
      :class="{'border-primary' : subNavOpen}"
    >
      {{ navItem.channel }}
      <Icon
        name="ChevronDown"
        class="hidden lg:block ml-1 pointer-events-none transition-transform transform duration-200 text-orange text-orange-default"
        :class="{ 'rotate-180': hasHover || subNavOpen }"
      />
    </a>

    <button
      :data-cy="`navV2-nav-link-${navItem.channel}`"
      v-if="!navItem.isLink"
      ref="nav-channel-trigger"
      :disabled="buttonDisabled"
      @focus="focus()"
      @click="
        toggleSubNav('space');
        $generalClick({'category': 'Menu Interaction', 'label': `Top Nav Click - ${navItem.channel}`}, $event);
        $ga4GeneralClick({
          event: 'top_nav',
          module: navItem.channel.toLowerCase(),
          action: 'click',
          content_display: navItem.channel.toLowerCase()
        })
      "
      :aria-controls="`${navItem.channel} menu`"
      :aria-expanded="subNavOpen ? 'true' : 'false'"
      class="w-auto inline-flex py-4 px-4 lg:px-0 items-center font-semibold text-secondary lg:border-b-4 border-white hover:border-primary focus:border-primary"
      :class="{'border-primary' : subNavOpen}"
    >
      {{navItem.channel}}
      <Icon
        name="ChevronDown"
        class="hidden lg:block ml-1 pointer-events-none transition-transform transform duration-200 text-orange text-orange-default"
        :class="{ 'rotate-180': hasHover || subNavOpen }"
      />
    </button>

    <button
      :data-cy="`navV2-nav-subnav-btn-${navItem.channel}`"
      type="button"
      :aria-label="`${subNavOpen ? 'Close': 'Open'} ${navItem.channel} sub navigation`"
      class="group ml-auto w-auto mr-2 md:mr-6 lg:hidden"
      @click="
        toggleSubNav();
        $generalClick({'category': 'Menu Interaction', 'label': `Sub Menu Nav Click - ${navItem.channel} sub navigation`}, $event);
        $ga4GeneralClick({
          event: 'top_nav',
          module: navItem.channel.toLowerCase(),
          action: subNavOpen ? 'expand arrow' : 'collapse arrow'
        })
      "
    >
      <Icon
        name="ChevronDown"
        width="32"
        height="32"
        class="transition duration-200 transform text-orange text-orange-default"
        :class="{ 'rotate-180': subNavOpen }"
      />
    </button>

    <ChannelSubNav
      ref="subNav"
      :subNav="navItem.subNav"
      :title="navItem.title || navItem.channel"
      :channel="navItem.channel"
      :subNavOpen="subNavOpen"
      :opened-with-space-key="openedWithSpaceKey"
      @closedWithSpaceKey="closeSubNav(true)"
    ></ChannelSubNav>
  </li>
</template>

<script>
import Icon from '../Icon.vue'
import ChannelSubNav from './ChannelSubNav.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Icon,
    ChannelSubNav
  },
  props: {
    navItem: {
      type: Object,
      default: () => {},
    },
    selectedItem: String
  },
  data() {
    return {
      subNavOpen: false,
      hasHover: false,
      buttonDisabled: false,
      openedWithSpaceKey: false
    }
  },
  computed: {
    ...mapGetters(['layout'])
  },
  methods: {
    focus(){
      if(this.layout.includes('desktop') && !this.subNavOpen && this.selectedItem !== this.navItem.channel){
        this.openedWithSpaceKey = false
        this.$emit('navOpenChange', null)
        this.$root.$emit('showNavOverlay', false)
      }
    },
    mouseEnterEvt() {
      if(this.layout.includes('desktop')){
        this.hasHover = true
        this.buttonDisabled = true
        this.openSubNav()
        this.$ga4GeneralClick({
          event: 'top_nav',
          module: this.navItem.channel.toLowerCase(),
          action: 'auto open'
        })
        setTimeout(function(){
          // horrid little hack to sort out touch issues between iPad and Windows touch devices (only one tested so far)
          this.buttonDisabled = false
        }.bind(this), 100)
      }
    },
    mouseLeaveEvt() {
      if(this.layout.includes('desktop')){
        this.hasHover = false
        this.buttonDisabled = false
        this.closeSubNav()
      }
    },
    toggleSubNav(evt) {    
      if(!this.subNavOpen){
        this.openSubNav()
      }
      else {    
        this.closeSubNav()
      }

      if(evt === 'space'){  
        this.openedWithSpaceKey = true
        this.$emit('navOpenChange', this.navItem.channel)
        this.$root.$emit('showNavOverlay', this.subNavOpen)
      }
    },
    openSubNav() {
      this.$emit('navOpenChange', this.navItem.channel)
      this.subNavOpen = true
      this.$root.$emit('showNavOverlay', true)
    },
    closeSubNav(focus) {
      this.subNavOpen = false
      this.$emit('navClose')
      this.openedWithSpaceKey = false
      this.$ga4GeneralClick({
        event: 'top_nav',
        module: this.navItem.channel.toLowerCase(),
        action: 'auto close'
      })
      if(focus){
        this.$root.$emit('showNavOverlay', false)
        this.$refs['nav-channel-trigger'].focus()
      }
    }
  },
  watch: {
    selectedItem: function(newVal) {
      this.subNavOpen = newVal === this.navItem.channel
    }
  }
}
</script>
